var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "view" }, [
      _c("section", { staticClass: "section h-mt-100" }, [
        _c("div", { staticClass: "container" }, [
          _c("h1", { staticClass: "sectionTitle" }, [
            _vm._v("THECHARTS TERMS OF SERVICE"),
          ]),
          _c("p", [
            _vm._v(
              "Please read these terms carefully as they set out the terms of use of the TheCharts™ website (whether as a guest or registered user), associated websites and platforms and the terms on which we provide our services and access to any features. Use of our website includes accessing, browsing, or registering to use our site.We may change these terms so please check from time to time to take note of any changes. By using our website or services you are deemed to agree to these terms. If you do not agree to any part of these terms you must not use our website or services. "
            ),
          ]),
          _c("br"),
          _c("h2", [_vm._v("General Terms Of TheCharts™ Website Use")]),
          _c("h3", { staticClass: "title" }, [
            _vm._v("Content on Our Website"),
          ]),
          _c("p", [
            _vm._v(
              "Access to our website is granted free of charge and on a temporary basis.We do not guarantee that our website or any part of it will always be available or be uninterrupted. We may suspend or change any part of our website without notice and we will not be liable if our website is unavailable. You are responsible for arranging your own access to the website and any service provider charges that may be incurred. We will update our website from time to time but please note that any of the content may be out of date and you should not rely on it. We do not guarantee that our site, or any content on it, will be free from errors or omissions. Where our website contains links to other sites and resources provided by third parties, these links are provided for your information only. We have no control over the contents of those sites or resources. You may link to our website but only in a way that is fair and legal and does not damage our reputation or take advantage of it. "
            ),
          ]),
          _c("br"),
          _c("h3", { staticClass: "title" }, [
            _vm._v("TheCharts™ Intellectual property rights"),
          ]),
          _c("p", [
            _vm._v(
              "We are the owner or the licensee of all intellectual property rights in our website, and in the materials published on it or supplied to you. This includes any software, audio, text, photographs, graphics, videos and any trade marks or brand rights and any other works which are protected by laws and treaties around the world. All of our intellectual property rights are reserved.Any materials we supply or provide you (including the Beat Store and Marketplace – defined below) are for your personal use and should not be distributed or copied without our prior permission. You must not use any part of the content or materials on our website for any purposes without obtaining a license to do so from us or our licensors. Should you wish to exploit any of our or our licensors’ materials in a manner which is not covered by the explicit written license terms on our website, please contact support@TheCharts.com. If you print off, copy or download any part of our website in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. "
            ),
          ]),
          _c("br"),
          _c("h3", { staticClass: "title" }, [_vm._v("Viruses")]),
          _c("p", [
            _vm._v(
              "You must not misuse our website or services by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to our website or services, the server on which our website is stored or any server, computer or database connected to our website. You must not attack our website via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our website will cease immediately.It is important that you protect your device from viruses and malware. We will not accept responsibility for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material on or downloaded from our website, or on any website linked to it. "
            ),
          ]),
          _c("br"),
          _c("h3", { staticClass: "title" }, [_vm._v("Complaints")]),
          _c("p", [
            _vm._v(
              "Should you have any issues with our website or service please contact support@TheCharts.com.TheCharts is not responsible for any infringing, illegal or offensive Beats or other materials uploaded by Producers or any third parties on our website (Infringing Materials). If you wish to notify us of any Infringing Material please email support@TheCharts.com. We are under no obligation to take any action, beyond what is required by any relevant laws or authorities. "
            ),
          ]),
          _c("br"),
          _c("h2", [_vm._v("Buying Beats On TheCharts™")]),
          _c("h3", { staticClass: "title" }, [_vm._v("Conditions Of Sale")]),
          _c("p", [
            _vm._v(
              "Compositions and recordings (Beats) are made available by third parties (Producers) through our platforms. TheCharts requires all Producers to confirm that the Producer’s Beats do not infringe any third party rights and that they have the necessary permissions to make the Beats available and grant ongoing rights to purchasers.TheCharts provide a platform for the Producers to sell their Beats. As a purchaser, your contractual relationship relating to Beats is with the licensor or seller of Beats and not with TheCharts. When you purchase Beats, it will be under the conditions and limitations of the license terms stipulated by the relevant Producer. These terms will be presented prior to completing the purchase of a Beat. If you do not agree to those license terms, you must not purchase that Beat. Any exploitation of Beats by a purchaser which is outside of the Producer’s license terms will be a potential breach of contract and a potential breach of intellectual property rights, for which you will be liable. "
            ),
          ]),
          _c("br"),
          _c("h3", { staticClass: "title" }, [_vm._v("Purchaser obligations")]),
          _c("p", [
            _vm._v(
              "We wish to promote an environment where people can share their music and be properly remunerated for their work. By accessing our website, you agree that you shall not breach another party’s intellectual property rights and that you will adhere to the terms of any license agreement.In the event that in our reasonable belief a party breaches these terms, any Producer license terms or infringes ours or a Producer’s intellectual property rights we reserve the right (without limiting any other rights and remedies) to immediately terminate or suspend your account and share your account details (including personal information) with the relevant rights holder or authorities. You shall indemnify us against any and all direct and indirect claims, losses, damages, costs and liabilities arising out of any infringement by you or permitted by of a Producer’s rights."
            ),
          ]),
          _c("h2", [_vm._v("Selling Beats On TheCharts™")]),
          _c("h3", { staticClass: "title" }, [_vm._v("Selling Services")]),
          _c("p", [
            _vm._v(
              "We offer two ways of selling your Beats (Producer Services):Beat Store - We provide software that enables Producers to create an online store which can be embedded into websites and other platforms such as Facebook (with the website and platform owner’s permission). This gives Producers the tools to sell directly to purchasers. Marketplace - Producers can make Beats available for purchase via TheCharts’s website. There is no obligation to sell through our Marketplace. Producers may remove Beats from our Marketplace by changing its Seller Preferences. "
            ),
          ]),
          _c("br"),
          _c("h3", { staticClass: "title" }, [
            _vm._v("Intellectual Property Rights"),
          ]),
          _c("p", [
            _vm._v(
              "The Producer grants to TheCharts a non-exclusive, worldwide, royalty-free, transferable license to copy, edit, store, distribute and otherwise exploit the Producer Materials (defined below) for the purpose of: "
            ),
          ]),
          _c("ul", [
            _c("li", [
              _c("p", [
                _vm._v(
                  "making the Beats available as a stream (i.e. transmit the Beats in a manner that will not result in a reproduction of the Beats being made on a local storage device by a consumer for future use) for the purposes of enabling potential purchasers to preview Beats; "
                ),
              ]),
            ]),
            _c("li", [
              _c("p", [
                _vm._v(
                  "displaying the Producer Materials (excluding Beats) on its Producer Services; and "
                ),
              ]),
            ]),
            _c("li", [
              _c("p", [
                _vm._v(
                  "using the Producer Materials for the promotion and marketing or TheCharts."
                ),
              ]),
            ]),
          ]),
          _c("p", [
            _vm._v(
              "TheCharts grants to the Producer a limited, non-exclusive, non-transferable, non-sublicensable, revocable right to use the Beat Store and its underlying intellectual property for the purpose of selling Beats. By embedding the Beat Store, you agree that we may use information collected through the Beat Store in accordance with our privacy policy. "
            ),
          ]),
          _c("br"),
          _c("h3", { staticClass: "title" }, [_vm._v("Producer Obligations")]),
          _c("p", [
            _vm._v(
              "By uploading Beats and any associated artwork or other materials, including your name, biography and photograph (together Producer Materials) through our Producer Services, Producers warrant and represent that: "
            ),
          ]),
          _c("ul", [
            _c("li", [
              _c("p", [
                _vm._v(
                  "you have the authority and permission to make the Producer Materials available in the manner offered by you;"
                ),
              ]),
            ]),
            _c("li", [
              _c("p", [
                _vm._v(
                  "the Producer Materials do not infringe the rights of any third party, including any sample owners, writers, artists or musicians; "
                ),
              ]),
            ]),
            _c("li", [
              _c("p", [
                _vm._v(
                  "the Producer Materials shall not contain or transmit any virus or other harmful matter; "
                ),
              ]),
            ]),
            _c("li", [
              _c("p", [
                _vm._v(
                  "you will not purchase your own Beats or engage in any other activity to unfairly manipulate the appearance of your Beats listing on the Marketplace or within the TheCharts charts; "
                ),
              ]),
            ]),
            _c("li", [
              _c("p", [
                _vm._v(
                  "you will not make copies of the Beat Store or Marketplace or attempt to modify, edit, disassemble, extract code or reverse engineer the same; "
                ),
              ]),
            ]),
            _c("li", [
              _c("p", [
                _vm._v(
                  "you will display the Beat Store or Marketplace without alteration; "
                ),
              ]),
            ]),
            _c("li", [
              _c("p", [
                _vm._v(
                  "you will not use the Beat Store or Marketplace for any illegal, unauthorised or offensive purposes; "
                ),
              ]),
            ]),
            _c("li", [
              _c("p", [
                _vm._v(
                  "you will comply with all laws and regulations relating to the sale of Beats; and "
                ),
              ]),
            ]),
            _c("li", [
              _c("p", [
                _vm._v(
                  "you shall be responsible for any taxes, levies or duties that are due in respect of the Producer’s use of the Producer Services. "
                ),
              ]),
            ]),
          ]),
          _c("p", [
            _vm._v(
              "We reserve the right to terminate, suspend or remove any account or content which we believe breaches our terms and conditions, without liability to you. You shall indemnify us against any and all direct and indirect claims, losses, damages, costs and liabilities arising out of any breach by you of these terms. "
            ),
          ]),
          _c("br"),
          _c("h3", { staticClass: "title" }, [_vm._v("Subscription")]),
          _c("p", [
            _vm._v(
              "Access to our Producer Services is provided on a recurring subscription basis of six monthly or twelve monthly periods. Subscriptions will automatically renew at the end of each subscription period unless you cancel the subscription by giving us one calendar months’ notice by email to support@TheCharts.com. The cancellation will take effect the day after the last day of your current subscription period – it is not possible to terminate a subscription part way through a subscription period.In the event any payment of a subscription fails we may suspend access to the Producer Services until any outstanding charges have been paid in full. The price of our various subscriptions are advertised on our website. We may change the subscription charge or features at any time. If we do so, we will send you an email a reasonable time in advance. The change will take effect from the next subscription period following the date of the change and you will be deemed to accept the new terms if you do not unsubscribe. "
            ),
          ]),
          _c("br"),
          _c("h3", { staticClass: "title" }, [
            _vm._v("Prices, Payment and Fees"),
          ]),
          _c("p", [
            _vm._v(
              "Producers can set the sale price of Beats at their discretion, however, a minimum purchase price of US$10 will apply to any Beats offered for sale through the Marketplace.When Beats are purchased, the payment will be paid directly to a Producer’s nominated PayPal account within 7 working days. TheCharts will deduct a commission from the price of any purchases of Beats through our Marketplace. Our commission will be automatically deducted before any revenue is paid through to the Producer. The Producer will receive 70% of the amounts from each sale through the Marketplace."
            ),
          ]),
          _c("br"),
          _c("h2", [_vm._v("Other Services On TheCharts™")]),
          _c("h3", { staticClass: "title" }, [_vm._v("Marketplace Promotion")]),
          _c("p", [
            _vm._v(
              "Producers may pay to feature Beats on our homepage. You are able to place an order for a Marketplace promotion through our website. Once your order has been placed we will send an email to you to confirm that your order has been accepted and our contract with you will come into force.You must purchase a minimum promotional period of 7 days, which will run consecutively. You may feature different Beats throughout the promotional period but may only feature one Beat a day. Once a Beat has been selected for a particular day it may not be changed for 24 hours so you must ensure that you have correctly selected the Beat you want featured that day. All features will rotate on the homepage to give all Beats the same visibility. Your Beat will feature on the homepage at some point, however, the frequency will change depending on the number of other Beats featured during the same time period. TheCharts do not make any guarantees that this feature will increase sales. There may be instances where we are unable to accept your order, for example, due to high demand or technical issues. If this is this case, we will send an email confirming that we have rejected your order and refund any charges paid for the Marketplace promotion. "
            ),
          ]),
          _c("br"),
          _c("h3", { staticClass: "title" }, [_vm._v("Custom Voice Tags")]),
          _c("p", [
            _vm._v(
              "Our services may be engaged to supply pre-recorded or customized audio voice notes to be used on top of your Beats (Voice Notes). You are able to place an order for a Voice Note through our website. Once your order has been placed we will send an email to you to confirm that your order has been accepted and our contract with you will come into force.The price of the Voice Note will be advertised on our website. We aim to record and email Voice Tags to you within 48 hours of accepting your order. Time shall not be of the essence. Subject to our receipt of payment for the Voice Tag, we grant to you a non-exclusive, non-transferable, non-sublicensable, worldwide license to reproduce the Voice Tag selected or commissioned on any Beats sold through Producer Services. Voice Tags are intended as a watermarking tool used to deter unauthorised copying of your Beats. We do not make any guarantee as to the effectiveness of Voice Notes and accept no liability for any unauthorised use of your Beats by third parties. There may be instances where we are unable to accept your order, for example, due to voice artist availability, technical issues or where we are unable to meet a delivery deadline you have specified. If this is this case, we will send an email confirming that we have rejected your order and refund any charges paid for the Voice Tag. In the event that the Voice Tag we deliver is faulty you shall have the right to a substitute or to cancel the order. In the event that the Voice Tag has been already recorded and you wish to cancel your order, you will not be entitled to a refund. This does not affect your statutory rights. "
            ),
          ]),
          _c("br"),
          _c("h3", { staticClass: "title" }, [
            _vm._v("Learn To Sell Beats Course"),
          ]),
          _c("p", [
            _vm._v(
              "Our services may be engaged to provide information and advice on marketing and monetising your Beats online. The details of the courses and associated prices are advertised on our website.You may not cancel your order for the course once you have accessed the course. This does not affect your statutory rights. We do not make any guarantee as to the effectiveness of courses. "
            ),
          ]),
          _c("br"),
          _c("h2", [_vm._v("General Terms")]),
          _c("h3", { staticClass: "title" }, [_vm._v("Liability")]),
          _c("p", [
            _vm._v(
              "If we do not comply with these terms, we shall be responsible for any loss or damage which could obviously be foreseen at the time we provide our services. All other liability is excluded. Nothing in these terms affects your statutory rights.We will have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity. We do not exclude any liability where it would be unlawful to do so, including for death or personal injury caused by our negligence, fraudulent behaviour or for breach of your legal rights in relation to the products. Where it is not possible to exclude liability, our aggregate liability to you shall be £300 (three hundred pounds). "
            ),
          ]),
          _c("br"),
          _c("h3", { staticClass: "title" }, [_vm._v("Miscellaneous")]),
          _c("p", [
            _vm._v(
              "We shall be permitted transfer our rights and obligations under these terms to another party.You may only transfer your rights or your obligations under these terms to another person if we agree to this in writing. No third party shall have any rights under these terms. Where any of these provisions are found to be unlawful it shall, where possible, be construed to give a lawful meaning and where not possible it shall be deemed severed from these terms. The remainder of these terms shall remain in full force and effect. Any delay in enforcing our rights or bringing an action against you shall not be a waiver of our rights. Even if we delay in enforcing this contract, we can still enforce it later. If you are a business, you irrevocably agree that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with a Contract or its subject matter or formation (including non-contractual disputes or claims), however, nothing in these terms shall limit our right to take proceedings against you in any other court of competent jurisdiction, nor shall the taking by us of proceedings in any one or more jurisdictions preclude the taking by us of proceedings in any other jurisdictions, whether concurrently or not, to the extent permitted by the law of such other jurisdiction. "
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }